
      import AC from 'agora-chat'
      
      const APP_ID = '711169900#1357075';
      const CHANNEL_NAME = 'Main Chat';
      const USER_ID = 'test_user';

const appKey = APP_ID; // Your App ID
const conn = new AC.connection({ appKey: appKey });

window.userDetails = {};
window.groupDetails = {};

async function getToken() {
    console.log("----------");
    let token = localStorage.getItem("userUuid")
    const response = await fetch(`https://amezianbackend-378a44d933c2.herokuapp.com/generateToken?userUuid=${token}`);
    const data = await response.json();
    console.log(data);
    return data.token;
}
console.log("checkChat");

window.adminIds = [];
async function fetchAdminIds() {
    try {
        const response = await fetch('https://amezianbackend-378a44d933c2.herokuapp.com/api/admin-ids', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`  // Adjust based on your token storage
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch admin IDs');
        }

        const data = await response.json();
        window.adminIds = data.adminIds;  // Store admin IDs in the window object
        console.log('Admin IDs:', window.adminIds);
    } catch (error) {
        console.error('Error fetching admin IDs:', error);
    }
}

window.accessChat = async function() {
    try {
        const token = await getToken();
        console.log("Received token:", token);
        // Ensure the connection is open and authenticated
        conn.open({
            user: localStorage.getItem('userID'),  // Retrieve user ID correctly from localStorage
            agoraToken: token,  // Ensure this token is valid and has the appropriate permissions
        }).then(async function(){
            console.log("Connection established");

            const user = JSON.parse(localStorage.getItem('user'));
            window.uID = user.groupId;
            if (user && window.uID != undefined) {
                // Prepare the options for joining the group
                let options = {
                    groupId: window.uID
                };

                // Attempt to join the group
                conn.joinGroup(options).then(res => {
                    console.log("Joined group successfully:", res);
                }).catch(err => {
                    console.error("Error joining group:", err);
                }).finally(() => {
                    // Proceed to get history messages
                    /*
                    <div id="convDiv" class="convDiv">
                    <div class="convChat">
                        <img src="https://upcdn.io/12a1z3Z/raw/uploads/2024/07/30/4kSVQwkqBb-Ellipse%2090-2.png">
                        <div>
                            <p class="convChatName">Valérie Dupont</p>
                            <p class="convChatMessage">Hello</p>
                        </div>
                        <p class="convTime">14:54</p>
                    </div>
                </div>*/
                    
                    window.getHistoryMessages();
                });
            } else if (user && window.uID == undefined) {
                console.log("User or groupId is missing");
                if (window.adminIds.length === 0) {
                      console.log('Admin IDs not available. Fetching...');
                      await fetchAdminIds(); // Fetch admin IDs if not already fetched
                  }
                if (window.adminIds.length > 0){
                    console.log(window.adminIds);
                    let option = {
                      data: {
                          groupname: user._id,
                          members: window.adminIds,
                          desc: "A description of a group",
                          public: true,
                          approval: false,
                          allowinvites: true,
                          inviteNeedConfirm: false,
                          maxusers: 500
                      },
                      };

                      conn.createGroup(option).then(function(res){
                        window.updateUserField(user.email, "groupId", res.data.groupid);
                        window.uID = res.data.groupid;
                        window.getHistoryMessages();
                        console.log("check");
                      });
                    //window.updateUserField(myEmail, "shouldEmail", false);  
                }
            } else {
                console.log("User or groupId is missing");
            }
        }).catch(err => {
            console.error("Error establishing connection:", err);
        });
    } catch (error) {
        console.error("Error getting token:", error);
    }
}

window.getUserDetails = function(){
    return conn.getHistoryMessages({
        targetId: window.uID,
        chatType: 'groupChat',
        pageSize: 1000,
        searchDirection: 'up',
    }).then((res) => {
        // Process the response
        console.log("Messages", res.messages, res.isLast);
        res.messages.forEach(message => {
            const userId = message.from;
            if (!window.userDetails[userId]) {
                window.userDetails[userId] = {
                    name: "", 
                    lastName: "", 
                    profileImage: ""
                };
            }
        });

        // Fetch all user details and wait for completion
        return Promise.all(Object.keys(window.userDetails).map(userId => fetchUserDetails(userId)));
    });
};


window.getHistoryMessages = function(){
  window.receive = false;
  conn.getHistoryMessages({
                        targetId: window.uID,
                        chatType: 'groupChat',
                        pageSize: 50,
                        searchDirection: 'up',
                    }).then((res) => {
                        console.log("Messages", res.messages, res.isLast);
                        window.userDetails = {};
                        res.messages.forEach(message => {
                            const userId = message.from;
                            if (!window.userDetails[userId]) {
                                window.userDetails[userId] = {
                                    name: "", // Placeholder for the user's name
                                    lastName: "", // Placeholder for the user's last name
                                    profileImage: "" // Placeholder for the user's profile image
                                };
                            }
                        });

                        // Fetch all user details and wait for completion
                        Promise.all(Object.keys(window.userDetails).map(userId => fetchUserDetails(userId))).then(() => {
                        console.log("User details updated:", window.userDetails);

                        const userId = localStorage.getItem('userID'); // Retrieve current user's ID from localStorage
                        const messageContainer = document.getElementById('messages'); // The container to append messages to
                        messageContainer.innerHTML = ''; // Clear the existing messages
                        

                        const HALF_HOUR = 30 * 60 * 1000; // 30 minutes in milliseconds
                        let lastMessageTime = null;
                        let isFirstMessage = true;
                        res.messages.forEach((message, index, array) => {
                            let messageDiv;
                            let isFirstMessageFromUser = false;
                            let hasTimeGap = false;
                            let hasTimeGap2 = false;
                            
                            try{
                            isFirstMessageFromUser = index === array.length - 1 || array[index + 1].from !== message.from;
                            }
                            catch(e){}
                            
                            try{
                            if (index < array.length - 1) {
                                let timeDifference = array[index-1].time - array[index].time;
                                hasTimeGap = timeDifference > HALF_HOUR;
                            }
                          }catch(e){

                          }

                          try{
                            if (index < array.length - 1) {
                                let timeDifference2 = array[index].time - array[index+1].time;
                                hasTimeGap2 = timeDifference2 > HALF_HOUR;
                            }
                          }catch(e){

                          }
                            console.log(array[index].from, array[index].msg, index, array.length, isFirstMessageFromUser);

                            // Always display time for the first message or after a time gap
                            let timeMessageDiv;
                            try{
                            if (index == (array.length - 1) || hasTimeGap) {
                                timeMessageDiv = document.createElement('div');
                                timeMessageDiv.className = 'timeMessage';

                                // Format the time to "HH:MM"
                                let messageDate = new Date(array[index-1].time);
                                if (index == (array.length - 1)){
                                  messageDate = new Date(array[index].time);
                                }
                                const hours = messageDate.getHours().toString().padStart(2, '0');
                                const minutes = messageDate.getMinutes().toString().padStart(2, '0');
                                timeMessageDiv.textContent = `${hours}:${minutes}`;

                                // Append the timeMessageDiv before the actual message
                                if (index != (array.length - 1)){
                                  messageContainer.appendChild(timeMessageDiv);
                                }

                                // Mark that the first message has been processed
                                isFirstMessage = false;
                            }
                            }catch(e){
                            }

                            // Update the lastMessageTime
                            lastMessageTime = message.time;

                            /**
                             * Detect URLs in text and wrap them with an <a> tag,
                             * then replace line breaks (\n) with <br>.
                             */
                            function formatMessageText(rawText) {
                              // A simple URL regex for http/https links.
                              // Real-world URL detection can be more nuanced.
                              const urlRegex = /(https?:\/\/[^\s]+)/gi;

                              // 1) Wrap the URL in <a> tags
                              let replacedText = rawText.replace(urlRegex, url => {
                                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                              });

                              // 2) Replace any `\n` with a <br>
                              replacedText = replacedText.replace(/\n/g, '<br>');

                              return replacedText;
                            }


                            if (message.type === "txt") {
                                // This is a text message
                                if (message.from === userId) {
                                    // This is a toMessage
                                    messageDiv = document.createElement('div');
                                    messageDiv.className = 'toMessage';
                                    messageDiv.innerHTML = formatMessageText(message.msg);
                                } else {
                                    // This is a fromMessage
                                    messageDiv = document.createElement('div');
                                    messageDiv.className = 'fromMessage';
                                    const textHtml = `<div class="fromText">${formatMessageText(message.msg)}</div>`;
                                    messageDiv.innerHTML = textHtml;

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap2) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        messageDiv.innerHTML = `<div class="fromUser">${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}</div>` + messageDiv.innerHTML;
                                    }
                                }
                            } else if (message.type === "audio") {
                                return;
                                // This is an audio message
                                messageDiv = document.createElement('div');
                                messageDiv.className = message.from === userId ? 'toMessage' : 'fromMessage';

                                const audioElement = document.createElement('audio');
                                audioElement.controls = true;
                                audioElement.crossOrigin = "anonymous";  // Ensures the audio is fetched with CORS policies

                                const sourceElement = document.createElement('source');
                                sourceElement.src = message.url;
                                sourceElement.type = "audio/mpeg";

                                audioElement.appendChild(sourceElement);
                                audioElement.innerHTML += "Your browser does not support the audio element.";

                                if (message.from !== userId) {
                                    const fromTextDiv = document.createElement('div');
                                    fromTextDiv.className = 'fromText';
                                    fromTextDiv.appendChild(audioElement);
                                    messageDiv.appendChild(fromTextDiv);

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap2) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        const fromUserDiv = document.createElement('div');
                                        fromUserDiv.className = 'fromUser';
                                        fromUserDiv.textContent = `${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}`;
                                        messageDiv.prepend(fromUserDiv);
                                    }
                                } else {
                                    messageDiv.appendChild(audioElement);
                                }
                            } else if (message.type === "file") {
                                // This is a file message
                                messageDiv = document.createElement('div');
                                messageDiv.className = message.from === userId ? 'toMessage' : 'fromMessage';

                                const fileLink = document.createElement('a');
                                fileLink.href = message.url;
                                fileLink.target = "_blank"; // Opens the file in a new tab
                                fileLink.textContent = message.filename; // Display the file name

                                if (message.from !== userId) {
                                    const fromTextDiv = document.createElement('div');
                                    fromTextDiv.className = 'fromText';

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap2) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        const fromUserDiv = document.createElement('div');
                                        fromUserDiv.className = 'fromUser';
                                        fromUserDiv.textContent = `${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}`;
                                        messageDiv.prepend(fromUserDiv);
                                    }

                                    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
                                    const fileExtension = message.filename.split('.').pop().toLowerCase();

                                    if (imageExtensions.includes(fileExtension)) {
                                        const imagePreview = document.createElement('img');
                                        imagePreview.src = message.url;
                                        imagePreview.alt = message.filename;
                                        imagePreview.style.maxWidth = "100%";
                                        imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                                        fromTextDiv.appendChild(imagePreview);
                                    } else {
                                        fromTextDiv.appendChild(fileLink);
                                    }

                                    messageDiv.appendChild(fromTextDiv);

                                    
                                } else {
                                    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
                                    const fileExtension = message.filename.split('.').pop().toLowerCase();

                                    if (imageExtensions.includes(fileExtension)) {
                                        const imagePreview = document.createElement('img');
                                        imagePreview.src = message.url;
                                        imagePreview.alt = message.filename;
                                        imagePreview.style.maxWidth = "100%";
                                        imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                                        messageDiv.appendChild(imagePreview);
                                    } else {
                                        messageDiv.appendChild(fileLink);
                                    }
                                }
                            }

                            messageDiv.setAttribute('data-time', message.time);
                            messageDiv.setAttribute('data-user', message.from);
                            messageContainer.appendChild(messageDiv); // Append the created messageDiv to the container
                            if (index == (array.length - 1)){
                                  messageContainer.appendChild(timeMessageDiv);
                            }

                            // Add CSS for the first of type message from each user dynamically
                            if (!document.querySelector(`style.fromUser-style-${message.from}`)) {
                                let style = document.createElement('style');
                                style.innerHTML = `.fromUser-${message.from} .fromText:before { 
                                    content: '';
                                    display: block;
                                    width: 3.863vh;
                                    height: 3.863vh;
                                    position: absolute;
                                    top: 0;
                                    left: -0.86vw;
                                    background: url('${window.userDetails[message.from]?.profileImage || 'default-profile.png'}');
                                    background-size: cover;
                                }
                                .fromUser-${message.from} {
                                    margin-top: 2.5vh;
                                }`;
                                document.head.appendChild(style); // Append the style element to the head for CSS
                            }
                        });

                        setTimeout(() => {
                          window.receive = true;
                        }, "1000");

                        let myEmail = JSON.parse(localStorage.getItem('user')).email;
                        window.updateUserField(myEmail, "shouldEmail", false);

                        if (res.isLast){
                              console.log("RUNSSSSSSS")
                                const messageDiv2 = document.createElement('div');
                                messageDiv2.classList.add('fromMessage');
                                messageDiv2.innerHTML = `
                                  <div class="fromUser">Hassan A.</div>
                                  <div class="fromText fromHassan">
                                   Bonjour, c’est un plaisir de vous accueillir au Centre de Médecine et Nutrition Amezian.<br><br>

Je suis Hassan, directeur du centre, et je tiens à vous expliquer le début de votre parcours de soin.<br><br>

Grâce à votre bilan métabolique, nos médecins spécialistes vous enverront sous 24 heures ouvrées votre ordonnance personnalisée, directement dans votre application.<br><br>

Vous pourrez la télécharger en cliquant en haut à droite de votre écran dans l'onglet "bilan initial".<br><br>

Dès que vous nous transmettrez vos résultats sanguins dans ce même onglet, nos médecins les analyseront minutieusement pour établir un diagnostic précis de votre situation physiologique et hormonale.<br><br>

Votre compte rendu médical nous permettra de vous expliquer avec clarté l’état complet de votre métabolisme.<br><br>

Par la suite, toute notre équipe médicale collaborera pour élaborer votre protocole nutritionnel sur mesure, spécifiquement adapté à vos résultats sanguins.<br><br>

Ce protocole sera conçu pour optimiser vos fonctions hormonales, afin d’atteindre avec certitude vos objectifs.<br><br>

En attendant, contactez-nous exclusivement sur ce canal pour toute question concernant votre bilan sanguin.<br><br>

Nous sommes ravis de vous accompagner tout au long de ce processus et restons disponibles pour répondre à toute autre interrogation.<br><br>

Prenez soin de vous et à très bientôt.
                                  </div>
                                `;
                                if ($(".fromHassan").length == 0){
                                  document.querySelector("#messages").appendChild(messageDiv2);
                                }
                            }
                        

                        if ($(".page[type='loading']").hasClass("active")){
                          $(".page[type='loading']").removeClass("active");
                          $(".page[type='chat']").addClass("active");
                        }

                        /*
                        var scrollableDiv = document.getElementById('chat');
                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "300");

                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "500");

                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "700");

                        setTimeout(() => {
                          scrollableDiv = document.getElementById('chat');
                          scrollableDiv.scrollTop = 10000000000;
                        }, "1000");
                        
                        if (!(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))) {
                          var scrollableDiv = document.getElementById('chat');
                          setTimeout(() => {
                            scrollableDiv = document.getElementById('chat');
                            scrollableDiv.scrollTop = 10000000000;
                          }, "300");

                          setTimeout(() => {
                            scrollableDiv = document.getElementById('chat');
                            scrollableDiv.scrollTop = 10000000000;
                          }, "500");

                          setTimeout(() => {
                            scrollableDiv = document.getElementById('chat');
                            scrollableDiv.scrollTop = 10000000000;
                          }, "700");

                          setTimeout(() => {
                            scrollableDiv = document.getElementById('chat');
                            scrollableDiv.scrollTop = 10000000000;
                            // Select the scrolling parent and the #messages container
                            let scrollingParent = document.querySelector('#chat'); // Scrolling parent element
                            let messagesContainer = document.querySelector('#messages'); // Message container

                            let isScrolling; // Variable to track if the user is scrolling

                            // Add a scroll event listener to the parent
                            scrollingParent.addEventListener('scroll', function() {

                              window.clearTimeout(isScrolling);
                              
                              // Check if the scroll position is at the top
                              isScrolling = setTimeout(function() {
                                // Define a threshold for triggering the event near the top
                                const threshold = 100; // Adjust this value as needed (e.g., 50px or 100px from the top)

                                if (scrollingParent.scrollTop < threshold) {
                                  // Record the current scroll height before loading new messages
                                  const previousScrollHeight = scrollingParent.scrollHeight;

                                  // Find the first .fromMessage or .toMessage in #messages
                                  const firstMessage = messagesContainer.querySelector('.fromMessage, .toMessage');

                                  if (firstMessage) {
                                    const dataTime = firstMessage.getAttribute('data-time');
                                    console.log('First message data-time:', dataTime);

                                    try {
                                      $("body").addClass("loading");
                                      
                                      // Load previous messages
                                      window.getPreviousMessages(dataTime).then(() => {
                                        // After loading, adjust the scroll position to maintain the same place
                                        setTimeout(() => {
                                          const newScrollHeight = scrollingParent.scrollHeight;
                                          scrollingParent.scrollTop += (newScrollHeight - previousScrollHeight);
                                          console.log(scrollingParent.scrollTop, newScrollHeight, previousScrollHeight);
                                        }, "800");
                                        
                                        console.log("loaded")
                                        
                                        // Remove loading class
                                        $("body").removeClass("loading");
                                      }).catch((e) => {
                                        // In case of an error, remove the loading class
                                        $("body").removeClass("loading");
                                        console.error(e);
                                      });
                                    } catch (e) {
                                      $("body").removeClass("loading");
                                      console.error(e);
                                    }
                                  }
                                }
                              }, 300); // Debounce with a 300ms delay
                            });


                          }, "1000");
                        }else{
                          let scrollingParent = document.querySelector('#chat'); // Change this to the parent class or ID
                          let messagesContainer = document.querySelector('#messages');

                            // Add a scroll event listener to the parent
                            let isScrolling; // Declare a variable to track if the user is scrolling

                              scrollingParent.addEventListener('scroll', function() {
                                  // Clear the timeout every time the user scrolls
                                  window.clearTimeout(isScrolling);

                                  // Log the current scroll values for debugging
                                  console.log(scrollingParent.scrollTop, scrollingParent.scrollHeight, scrollingParent.clientHeight);

                                  // Set a timeout to run after scrolling ends (e.g., 100ms delay)
                                  isScrolling = setTimeout(function() {
                                      // Define a threshold for triggering the event near the bottom
                                      const threshold = 100; // Adjust this value as needed (e.g., 50px or 100px from the bottom)

                                      // Check if the scroll position is within the threshold from the bottom
                                      if (scrollingParent.scrollHeight + scrollingParent.scrollTop - scrollingParent.clientHeight <= threshold) {
                                          // Find the first .fromMessage or .toMessage in #messages
                                          const firstMessage = messagesContainer.querySelector('.fromMessage, .toMessage');

                                          // If a message exists, log its data-time attribute
                                          if (firstMessage) {
                                              const dataTime = firstMessage.getAttribute('data-time');
                                              console.log('First message data-time:', dataTime);
                                              try{
                                              $("body").addClass("loading");
                                              window.getPreviousMessages(dataTime); // Fetch previous messages
                                              }catch(e){
                                                $("body").removeClass("loading");
                                              }
                                          }
                                      }
                                  }, 300); // 100ms delay (adjust as needed)
                              });


                        }*/

                          let scrollingParent = document.querySelector('#messages'); // Change this to the parent class or ID
                          let messagesContainer = document.querySelector('#messages');

                            // Add a scroll event listener to the parent
                            let isScrolling; // Declare a variable to track if the user is scrolling

                              scrollingParent.addEventListener('scroll', function() {
                                window.run = 0;
                                  // Clear the timeout every time the user scrolls
                                  window.clearTimeout(isScrolling);

                                  // Log the current scroll values for debugging
                                  console.log(scrollingParent.scrollTop, scrollingParent.scrollHeight, scrollingParent.clientHeight);

                                  // Set a timeout to run after scrolling ends (e.g., 100ms delay)
                                  isScrolling = setTimeout(function() {
                                    window.run++;
                                      // Define a threshold for triggering the event near the bottom
                                      const threshold = 100; // Adjust this value as needed (e.g., 50px or 100px from the bottom)

                                      // Check if the scroll position is within the threshold from the bottom
                                      if (scrollingParent.scrollHeight + scrollingParent.scrollTop - scrollingParent.clientHeight <= threshold  && window.run == 1 && document.querySelectorAll('#messages div').length > 30) {
                                          // Find the first .fromMessage or .toMessage in #messages
                                          let messages = messagesContainer.querySelectorAll('.fromMessage, .toMessage');
                                          let firstMessage = messages[messages.length - 1];  // Select the last one


                                          // If a message exists, log its data-time attribute
                                          if (firstMessage) {
                                              const dataTime = firstMessage.getAttribute('data-time');
                                              console.log('First message data-time:', dataTime);
                                              try{
                                              $("body").addClass("loading");
                                              window.getPreviousMessages(dataTime); // Fetch previous messages
                                              }catch(e){
                                                $("body").removeClass("loading");
                                              }
                                          }
                                      }
                                  }, 300); // 100ms delay (adjust as needed)
                              });
                        
                        

                            // Continue with other code that depends on updated userDetails
                            // You can add more logic here
                        }).catch(error => {
                            console.error("Error updating user details:", error);
                        });
                    }).catch(err => {
                        console.error("Error getting history messages:", err);
                    });
}

function fetchUserDetails(userId) {
    return fetch(`https://amezianbackend-378a44d933c2.herokuapp.com/api/user-details/${userId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming the token is stored in localStorage
        }
    })
    .then(response => response.json())
    .then(data => {
        if (window.userDetails[userId]) {
            window.userDetails[userId].name = data.name;
            window.userDetails[userId].lastName = data.lastName;
            window.userDetails[userId].profileImage = data.profileImage;
        }
    })
    .catch(error => console.error('Error fetching user details:', error));
}

window.getPreviousMessages = function(time){
  return conn.getHistoryMessages({
                        targetId: window.uID,
                        chatType: 'groupChat',
                        pageSize: 50,
                        searchDirection: 'up',
                        searchOptions: {
                          startTime: new Date('2023,11,9').getTime(),
                          endTime: new Date(Number(time)).getTime(),
                        },
                    }).then((res) => {
                        console.log("Messages", res.messages, res.isLast);
                        res.messages.forEach(message => {
                            const userId = message.from;
                            if (!window.userDetails[userId]) {
                                window.userDetails[userId] = {
                                    name: "", // Placeholder for the user's name
                                    lastName: "", // Placeholder for the user's last name
                                    profileImage: "" // Placeholder for the user's profile image
                                };
                            }
                        });

                        // Fetch all user details and wait for completion
                        Promise.all(Object.keys(window.userDetails).map(userId => fetchUserDetails(userId))).then(() => {
                        console.log("User details updated:", window.userDetails);

                        const userId = localStorage.getItem('userID'); // Retrieve current user's ID from localStorage
                        const messageContainer = document.getElementById('messages'); // The container to append messages to
                        

                        const HALF_HOUR = 30 * 60 * 1000; // 30 minutes in milliseconds
                        let lastMessageTime = null;
                        let isFirstMessage = true;
                        res.messages.forEach((message, index, array) => {
                            if (index === 0){
                              console.log("First Message", message);
                              return;
                            }
                            let messageDiv;
                            let isFirstMessageFromUser = false;
                            let hasTimeGap = false;
                            try{
                            isFirstMessageFromUser = index === 0 || array[index + 1].from !== message.from;
                            hasTimeGap = index > 0 && (message.time - array[index + 1].time > HALF_HOUR);
                            }catch(e){}

                            // Always display time for the first message or after a time gap
                            if (isFirstMessage || hasTimeGap) {
                                const timeMessageDiv = document.createElement('div');
                                timeMessageDiv.className = 'timeMessage';

                                // Format the time to "HH:MM"
                                const messageDate = new Date(message.time);
                                const hours = messageDate.getHours().toString().padStart(2, '0');
                                const minutes = messageDate.getMinutes().toString().padStart(2, '0');
                                timeMessageDiv.textContent = `${hours}:${minutes}`;

                                // Append the timeMessageDiv before the actual message
                                messageContainer.appendChild(timeMessageDiv);

                                // Mark that the first message has been processed
                                isFirstMessage = false;
                            }

                            // Update the lastMessageTime
                            lastMessageTime = message.time;

                            if (message.type === "txt") {
                                // This is a text message
                                if (message.from === userId) {
                                    // This is a toMessage
                                    messageDiv = document.createElement('div');
                                    messageDiv.className = 'toMessage';
                                    messageDiv.innerHTML = message.msg.replace(/\n/g, '<br>');
                                } else {
                                    // This is a fromMessage
                                    messageDiv = document.createElement('div');
                                    messageDiv.className = 'fromMessage';
                                    messageDiv.innerHTML = `<div class="fromText">${message.msg.replace(/\n/g, '<br>')}</div>`;

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        messageDiv.innerHTML = `<div class="fromUser">${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}</div>` + messageDiv.innerHTML;
                                    }
                                }
                            } else if (message.type === "audio") {
                              return;
                            } else if (message.type === "file") {
                                // This is a file message
                                messageDiv = document.createElement('div');
                                messageDiv.className = message.from === userId ? 'toMessage' : 'fromMessage';

                                const fileLink = document.createElement('a');
                                fileLink.href = message.url;
                                fileLink.target = "_blank"; // Opens the file in a new tab
                                fileLink.textContent = message.filename; // Display the file name

                                if (message.from !== userId) {
                                    const fromTextDiv = document.createElement('div');
                                    fromTextDiv.className = 'fromText';

                                    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
                                    if (isFirstMessageFromUser || hasTimeGap) {
                                        messageDiv.classList.add(`fromUser-${message.from}`);
                                        const fromUserDiv = document.createElement('div');
                                        fromUserDiv.className = 'fromUser';
                                        fromUserDiv.textContent = `${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}`;
                                        messageDiv.appendChild(fromUserDiv);
                                    }

                                    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
                                    const fileExtension = message.filename.split('.').pop().toLowerCase();

                                    if (imageExtensions.includes(fileExtension)) {
                                        const imagePreview = document.createElement('img');
                                        imagePreview.src = message.url;
                                        imagePreview.alt = message.filename;
                                        imagePreview.style.maxWidth = "100%";
                                        imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                                        fromTextDiv.appendChild(imagePreview);
                                    } else {
                                        fromTextDiv.appendChild(fileLink);
                                    }

                                    messageDiv.appendChild(fromTextDiv);

                                    
                                } else {
                                  
                                    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
                                    const fileExtension = message.filename.split('.').pop().toLowerCase();

                                    if (imageExtensions.includes(fileExtension)) {
                                        const imagePreview = document.createElement('img');
                                        imagePreview.src = message.url;
                                        imagePreview.alt = message.filename;
                                        imagePreview.style.maxWidth = "100%";
                                        imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                                        messageDiv.appendChild(imagePreview);
                                    } else {
                                        messageDiv.appendChild(fileLink);
                                    }
                                }
                            }

                            try{
                            messageDiv.setAttribute('data-time', message.time);
                            messageDiv.setAttribute('data-user', message.from);
                            }catch(e){}
                            messageContainer.appendChild(messageDiv); // Append the created messageDiv to the container

                            // Add CSS for the first of type message from each user dynamically
                            if (!document.querySelector(`style.fromUser-style-${message.from}`)) {
                                let style = document.createElement('style');
                                style.innerHTML = `.fromUser-${message.from} .fromText:before { 
                                    content: '';
                                    display: block;
                                    width: 3.863vh;
                                    height: 3.863vh;
                                    position: absolute;
                                    top: 0;
                                    left: -0.86vw;
                                    background: url('${window.userDetails[message.from]?.profileImage || 'default-profile.png'}');
                                    background-size: cover;
                                }
                                .fromUser-${message.from} {
                                    margin-top: 2.5vh;
                                }`;
                                document.head.appendChild(style); // Append the style element to the head for CSS
                            }
                            
                            $("body").removeClass("loading");
                        });
                        if (res.isLast){
                              console.log("RUNSSSSSSS")
                              $("body").removeClass("loading");
                                const messageDiv2 = document.createElement('div');
                                messageDiv2.classList.add('fromMessage');
                                messageDiv2.innerHTML = `
                                  <div class="fromUser">Hassan A.</div>
                                  <div class="fromText fromHassan">
                                   Bonjour, c’est un plaisir de vous accueillir au Centre de Médecine et Nutrition Amezian.<br><br>

Je suis Hassan, directeur du centre, et je tiens à vous expliquer le début de votre parcours de soin.<br><br>

Grâce à votre bilan métabolique, nos médecins spécialistes vous enverront sous 24 heures ouvrées votre ordonnance personnalisée, directement dans votre application.<br><br>

Vous pourrez la télécharger en cliquant en haut à droite de votre écran dans l'onglet "bilan initial".<br><br>

Dès que vous nous transmettrez vos résultats sanguins dans ce même onglet, nos médecins les analyseront minutieusement pour établir un diagnostic précis de votre situation physiologique et hormonale.<br><br>

Votre compte rendu médical nous permettra de vous expliquer avec clarté l’état complet de votre métabolisme.<br><br>

Par la suite, toute notre équipe médicale collaborera pour élaborer votre protocole nutritionnel sur mesure, spécifiquement adapté à vos résultats sanguins.<br><br>

Ce protocole sera conçu pour optimiser vos fonctions hormonales, afin d’atteindre avec certitude vos objectifs.<br><br>

En attendant, contactez-nous exclusivement sur ce canal pour toute question concernant votre bilan sanguin.<br><br>

Nous sommes ravis de vous accompagner tout au long de ce processus et restons disponibles pour répondre à toute autre interrogation.<br><br>

Prenez soin de vous et à très bientôt.
                                  </div>
                                `;
                                if ($(".fromHassan").length == 0){
                                  document.querySelector("#messages").appendChild(messageDiv2);
                                }
                            }
                            // Continue with other code that depends on updated userDetails
                            // You can add more logic here
                        }).catch(error => {
                            console.error("Error updating user details:", error);
                            $("body").removeClass("loading");
                        });
                    }).catch(err => {
                        console.error("Error getting history messages:", err);
                        $("body").removeClass("loading");
                    });
}

// Attach event to send button
document.getElementById("sendMessageButton").addEventListener("click", function() {
    sendMessage(); // Your send message logic

    // Refocus the textarea after sending the message
    document.getElementById("messageInput").focus();
});


document.getElementById("messageInput").addEventListener("keydown", function(event) {
  if (window.innerWidth > 768){
    if (event.key === "Enter" && !event.shiftKey && !event.altKey && !event.ctrlKey && !event.metaKey) {
        event.preventDefault(); // Prevent default Enter behavior
        sendMessage(); // Trigger the send function
    }
  }
});

function sendMessage() {
  if (document.getElementById("messageInput").value.length > 0){
  const user = JSON.parse(localStorage.getItem('user'));
  const messageInput = document.getElementById("messageInput").value;
  console.log("Message input:", messageInput);
  $('#messageInput2').html('');
  
  const option = {
    chatType: "groupChat",
    type: "txt",
    to: user.groupId, // Change 'to' field based on the actual recipient
    msg: messageInput,
  };
  const msg = AC.message.create(option);

  // Add the message to the chat immediately
  const messagesDiv = document.getElementById("messages");

  // Get the last message element to check the time
  const lastMessageElement = document.querySelector(".toMessage, .fromMessage");
  const currentTime = new Date().getTime();
  let lastMessageTime = 0;

  if (lastMessageElement) {
    lastMessageTime = parseInt(lastMessageElement.getAttribute("data-time"));
  }

  const halfHourInMillis = 30 * 60 * 1000; // 30 minutes in milliseconds

  // Determine if the time message should be added
  const shouldAddTimeMessage = !lastMessageElement || (currentTime - lastMessageTime > halfHourInMillis);

  if (shouldAddTimeMessage) {
    const timeElement = document.createElement("div");
    timeElement.classList.add("timeMessage");
    timeElement.textContent = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    messagesDiv.prepend(timeElement);
  }

  // Create and append the message element
  const messageElement = document.createElement("div");
  messageElement.classList.add("toMessage");
  messageElement.innerHTML = messageInput.replace(/\n/g, '<br>');
  messageElement.setAttribute("data-time", currentTime.toString());
  messagesDiv.prepend(messageElement);

  conn.send(msg)
    .then(() => {
      console.log("Message sent:", messageInput);
      document.getElementById("messageInput").value = ''; // Clear input after sending
      $('#messageInput').trigger('input');
      var scrollableDiv = document.getElementById('chat');
      setTimeout(() => {
        var scrollableDiv = $('#messages');
        scrollableDiv.animate({
            scrollTop: scrollableDiv[0].scrollHeight
        }, 1000); // Adjust the duration (1000ms = 1 second) as needed

      }, "100");
      try{
          window.updateUserFieldById(window.uID, "latestMessage", messageInput);
          window.updateUserFieldById(window.uID, "latestMessageTime", new Date().getTime());
          window.updateUserFieldById(window.uID, "isRead", false);
      }catch(e){}
    })
    .catch((error) => {
      console.error("Send message failed", error);
      // Optionally remove the message if sending failed
      messagesDiv.removeChild(messageElement);
      if (shouldAddTimeMessage) {
        messagesDiv.removeChild(timeElement);
      }
    });
  }
}

window.sendPrivateAudio = function(input, audioElement){
  var file = AC.utils.getFileUrl(input); // Assuming AC.utils.getFileUrl can handle this setup
    var allowType = {
        'mp3': true,
        'amr': true,
        'wmv': true
    };

    if (file.filetype.toLowerCase() in allowType) {
        var option = {
            type: 'audio',
            file: file,
            length: parseInt(file.data.size / 1024), // Length in seconds or size
            chatType: "groupChat",
            to: user.groupId, // Change 'to' field based on the actual recipient
            onFileUploadError: function () {
                console.log('onFileUploadError');
            },
            onFileUploadProgress: function (e) {
                console.log('Upload progress', e);
            },
            onFileUploadComplete: function () {
                console.log('onFileUploadComplete');
            },
            ext: { file_length: file.data.size }
        };

        var msg = AC.message.create(option);
        conn.send(msg).then(() => {
            console.log('Voice message sent successfully');
            const messagesDiv = document.getElementById("messages");
            const messageElement = document.createElement("div");
            messageElement.classList.add("toMessage");
            messageElement.appendChild(audioElement);
            messagesDiv.appendChild(messageElement);
        }).catch((e) => {
            console.error("Failed to send voice message:", e);
        });
    }
}

window.disconnect = 0;

// Utility function to compress images using canvas
function compressImage(file, maxSizeMB = 20, quality = 0.7) {
    return new Promise((resolve, reject) => {
        if (!file.type.startsWith('image/')) {
            // Not an image, resolve with the original file
            return resolve(file);
        }

        const img = new Image();
        const url = URL.createObjectURL(file);

        img.onload = () => {
            URL.revokeObjectURL(url);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Calculate new dimensions if needed
            const MAX_WIDTH = 1600;
            const MAX_HEIGHT = 1200;
            let width = img.width;
            let height = img.height;

            if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                if (width > height) {
                    height = Math.round((height *= MAX_WIDTH / width));
                    width = MAX_WIDTH;
                } else {
                    width = Math.round((width *= MAX_HEIGHT / height));
                    height = MAX_HEIGHT;
                }
            }

            // Set canvas dimensions to new image dimensions
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // Function to convert canvas to blob with the desired quality
            const compress = (currentQuality) => {
                return new Promise((res, rej) => {
                    canvas.toBlob((blob) => {
                        if (blob) {
                            res(blob);
                        } else {
                            rej(new Error('Canvas is empty'));
                        }
                    }, file.type, currentQuality);
                });
            };

            // Iteratively reduce quality until the file size is below maxSizeMB
            const iterativeCompress = async (currentQuality) => {
                try {
                    let blob = await compress(currentQuality);
                    while (blob.size / (1024 * 1024) > maxSizeMB && currentQuality > 0.1) {
                        currentQuality -= 0.1;
                        blob = await compress(currentQuality);
                    }
                    return new File([blob], file.name, { type: file.type });
                } catch (error) {
                    reject(error);
                }
            };

            iterativeCompress(quality).then(resolve).catch(reject);
        };

        img.onerror = () => {
            URL.revokeObjectURL(url);
            reject(new Error('Échec du chargement de l\'image'));
        };

        img.src = url;
    });
}

window.sendPrivateFile = async function(input) {
    try {
        const originalFile = input.files[0];
        if (!originalFile) {
            console.error("Aucun fichier sélectionné");
            alert("Aucun fichier sélectionné.");
            return;
        }

        let processedFile = originalFile;
        const MAX_SIZE_MB = 20;

        // Check if the file is an image
        const isImage = originalFile.type.startsWith('image/');

        if (isImage) {
            // Compress the image if it's an image
            console.log("Compression de l'image...");
            processedFile = await compressImage(originalFile, MAX_SIZE_MB);
            if (processedFile.size / (1024 * 1024) > MAX_SIZE_MB) {
                console.error("Impossible de compresser l'image en dessous de 20MB");
                alert("Le fichier est trop volumineux et ne peut pas être compressé en dessous de 20MB.");
                return;
            }
        } else {
            // For non-image files, check the size directly
            if (processedFile.size / (1024 * 1024) > MAX_SIZE_MB) {
                console.error(`La taille du fichier dépasse ${MAX_SIZE_MB}MB et ne peut pas être envoyé.`);
                alert(`Le fichier est trop volumineux. La taille maximale autorisée est de ${MAX_SIZE_MB}MB.`);
                return;
            }
        }

        // Proceed to send the (possibly compressed) file
        const fileURL = URL.createObjectURL(processedFile);
        const fileExtension = processedFile.name.split('.').pop().toLowerCase();

        // Create a file object compatible with Agora Chat
        const compressedFile = {
            url: fileURL,
            data: processedFile,
            size: processedFile.size,
            filename: processedFile.name,
            filetype: fileExtension // e.g., 'jpeg' from 'image/jpeg'
        };

        const option = {
            type: 'file', // Generic file type
            file: compressedFile,
            filename: compressedFile.filename, // File name to display
            chatType: "groupChat",
            to: user.groupId, // Change 'to' field based on the actual recipient
            onFileUploadError: function () {
                console.log('Erreur lors du téléchargement du fichier');
                alert("Erreur lors du téléchargement du fichier.");
            },
            onFileUploadProgress: function (e) {
                console.log('Progression du téléchargement', e);
                // Optionally, update a progress bar here
            },
            onFileUploadComplete: function () {
                console.log('Téléchargement du fichier terminé');
            },
            ext: { file_length: compressedFile.size } // Optional: Additional file metadata
        };

        const msg = AC.message.create(option);
        await conn.send(msg);
        console.log('Fichier envoyé avec succès', msg);
        const userInfo = JSON.parse(localStorage.user);

        let messageContainer = document.getElementById('messages'); 
        // This is a file message
        let messageDiv = document.createElement('div');
        messageDiv.className = 'toMessage';

        const fileLink = document.createElement('a');
        fileLink.href = msg.url;
        fileLink.target = "_blank"; // Opens the file in a new tab
        fileLink.textContent = msg.file.filename; // Display the file name

        const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
        const fileExt = fileExtension.toLowerCase();

        if (imageExtensions.includes(fileExt)) {
            const imagePreview = document.createElement('img');
            imagePreview.src = msg.url;
            imagePreview.alt = msg.file.filename;
            imagePreview.style.maxWidth = "100%";
            imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
            messageDiv.appendChild(imagePreview);
        } else {
            messageDiv.appendChild(fileLink);
        }

        messageDiv.setAttribute('data-time', msg.time);
        messageDiv.setAttribute('data-user', userInfo._id);
        messageContainer.prepend(messageDiv); // Append the created messageDiv to the container

        var scrollableDiv = $('#messages');
        scrollableDiv.animate({
            scrollTop: scrollableDiv[0].scrollHeight
        }, 1000); // Adjust the duration (1000ms = 1 second) as needed
                            
        // Optionally display the file in the chat UI
        try{
            window.updateUserFieldById(window.uID, "latestMessage", "Message de fichier");
            window.updateUserFieldById(window.uID, "latestMessageTime", new Date().getTime());
            window.updateUserFieldById(window.uID, "isRead", false);
        }catch(e){}
    } catch (error) {
        console.error("Échec de l'envoi du fichier:", error);
        alert("Échec de l'envoi du fichier: " + error.message);
    }
}

// Event listener for file send button
document.querySelector('.fileSend').addEventListener('click', function() {
    const fileInput = document.getElementById('fileInput'); // Ensure you have a file input element with this ID
    fileInput.click(); // Trigger file input click to open file picker

    fileInput.onchange = function() {
        if (fileInput.files.length > 0) {
            window.sendPrivateFile(fileInput);
            // Optionally, clear the input after sending
            fileInput.value = '';
        }
    };
});


conn.addEventHandler("connection&message", {
  onConnected: () => {
    //console.log("Connect success!");
    const logDiv = document.getElementById("messages");
  },
  onDisconnected: () => {
    window.disconnect = 1;
    //console.log("Disconnected!");
    const logDiv = document.getElementById("messages");
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    document.addEventListener('click', handleUserActivity);
    document.addEventListener('scroll', handleUserActivity);
    document.addEventListener('touchstart', handleUserActivity);

    function handleUserActivity(event) {
        window.disconnect++;
        if (window.disconnect == 2) {
          window.accessChat();
        }
    }

  },
  onTextMessage: (message) => {
    function messageReceived(message){
    console.log("Text message received:", message);
    let messageDiv;
    let timePrev = "";
    let messageContainer = document.getElementById('messages'); 
    let isFirstMessageFromUser = false;
    
    if ($("#messages > div").first().hasClass("toMessage") || $("#messages > div").first().attr("data-user") != message.from){
      isFirstMessageFromUser = true;
    }


    timePrev = $("#messages > div").first().attr("data-time");
    

    console.log("Check", isFirstMessageFromUser, timePrev);

    const hasTimeGap = timePrev ? (message.time - timePrev > 30 * 60 * 1000) : false;
    console.log("Check", isFirstMessageFromUser, hasTimeGap);
    // Always display time for the first message or after a time gap
    if (hasTimeGap) {
        console.log("Inside if block");
        const timeMessageDiv = document.createElement('div');
        timeMessageDiv.className = 'timeMessage';
        console.log("Inside if block");

        // Format the time to "HH:MM"
        const messageDate = new Date(message.time);
        const hours = messageDate.getHours().toString().padStart(2, '0');
        const minutes = messageDate.getMinutes().toString().padStart(2, '0');
        timeMessageDiv.textContent = `${hours}:${minutes}`;
        console.log("Inside if block");

        // Append the timeMessageDiv before the actual message
        messageContainer.prepend(timeMessageDiv);
        console.log("Inside if block");
    }

    console.log("Check2");

    /**
     * Detect URLs in text and wrap them with an <a> tag,
     * then replace line breaks (\n) with <br>.
     */
      function formatMessageText(rawText) {
      // A simple URL regex for http/https links.
      // Real-world URL detection can be more nuanced.
      const urlRegex = /(https?:\/\/[^\s]+)/gi;

      // 1) Wrap the URL in <a> tags
      let replacedText = rawText.replace(urlRegex, url => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });

      // 2) Replace any `\n` with a <br>
      replacedText = replacedText.replace(/\n/g, '<br>');

      return replacedText;
    }

    // This is a fromMessage
    messageDiv = document.createElement('div');
    messageDiv.className = 'fromMessage';
    const textHtml = `<div class="fromText">${formatMessageText(message.msg)}</div>`;
    messageDiv.innerHTML = textHtml;
    console.log("Message Div", messageDiv);

    // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
    if (isFirstMessageFromUser || hasTimeGap) {
        messageDiv.classList.add(`fromUser-${message.from}`);
        messageDiv.innerHTML = `<div class="fromUser">${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}</div>` + messageDiv.innerHTML;
    }
    messageDiv.setAttribute('data-time', message.time);
    messageDiv.setAttribute('data-user', message.from);
    messageContainer.prepend(messageDiv);

    if (!document.querySelector(`style.fromUser-style-${message.from}`)) {
                                let style = document.createElement('style');
                                style.innerHTML = `.fromUser-${message.from} .fromText:before { 
                                    content: '';
                                    display: block;
                                    width: 3.863vh;
                                    height: 3.863vh;
                                    position: absolute;
                                    top: 0;
                                    left: -0.86vw;
                                    background: url('${window.userDetails[message.from]?.profileImage || 'default-profile.png'}');
                                    background-size: cover;
                                }
                                .fromUser-${message.from} {
                                    margin-top: 2.5vh;
                                }`;
                                document.head.appendChild(style); // Append the style element to the head for CSS
                            }
    }
    if (window.receive){
      if (window.userDetails[message.from] === undefined) {
          window.getUserDetails().then(() => {
              messageReceived(message);
          });
      } else {
          messageReceived(message);
      }
      var myEmail = JSON.parse(localStorage.user).email;
      window.updateUserField(myEmail, "shouldEmail", false);
    }

  },
  onFileMessage: function (message) {
    if (window.receive){
      if (window.userDetails[message.from] === undefined) {
          window.getUserDetails().then(() => {
              messageReceived2(message);
          });
      } else {
          messageReceived2(message);
      }
    }
    function messageReceived2(message){
    let messageDiv;
    let timePrev = "";
    let messageContainer = document.getElementById('messages'); 
    let isFirstMessageFromUser = false;
    
    if ($("#messages > div").first().hasClass("toMessage") || $("#messages > div").first().attr("data-user") != message.from){
      isFirstMessageFromUser = true;
    }


    timePrev = $("#messages > div").first().attr("data-time");
    

    console.log("Check", isFirstMessageFromUser, timePrev);

    const hasTimeGap = timePrev ? (message.time - timePrev > 30 * 60 * 1000) : false;
    console.log("Check", isFirstMessageFromUser, hasTimeGap);
    // Always display time for the first message or after a time gap
    if (hasTimeGap) {
        console.log("Inside if block");
        const timeMessageDiv = document.createElement('div');
        timeMessageDiv.className = 'timeMessage';
        console.log("Inside if block");

        // Format the time to "HH:MM"
        const messageDate = new Date(message.time);
        const hours = messageDate.getHours().toString().padStart(2, '0');
        const minutes = messageDate.getMinutes().toString().padStart(2, '0');
        timeMessageDiv.textContent = `${hours}:${minutes}`;
        console.log("Inside if block");

        // Append the timeMessageDiv before the actual message
        messageContainer.prepend(timeMessageDiv);
        console.log("Inside if block");
    }

    console.log("Check2");

    messageDiv = document.createElement('div');
    messageDiv.className = 'fromMessage';

        const fileLink = document.createElement('a');
        fileLink.href = message.url;
        fileLink.target = "_blank"; // Opens the file in a new tab
        fileLink.textContent = message.filename; // Display the file name

        
            const fromTextDiv = document.createElement('div');
            fromTextDiv.className = 'fromText';

            const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
            const fileExtension = message.filename.split('.').pop().toLowerCase();

            if (imageExtensions.includes(fileExtension)) {
                const imagePreview = document.createElement('img');
                imagePreview.src = message.url;
                imagePreview.alt = message.filename;
                imagePreview.style.maxWidth = "100%";
                imagePreview.style.maxHeight = "200px"; // Limit the size of the preview
                fromTextDiv.appendChild(imagePreview);
            } else {
                fromTextDiv.appendChild(fileLink);
            }

            messageDiv.appendChild(fromTextDiv);

            // Add the fromUser class only if it's the first message in the sequence from the same user or after a time gap
            if (isFirstMessageFromUser || hasTimeGap) {
                messageDiv.classList.add(`fromUser-${message.from}`);
                const fromUserDiv = document.createElement('div');
                fromUserDiv.className = 'fromUser';
                fromUserDiv.textContent = `${window.userDetails[message.from]?.name || ''} ${window.userDetails?.[message.from]?.lastName ? window.userDetails[message.from].lastName[0] + '.' : ''}`;
                messageDiv.prepend(fromUserDiv);
            }
          
    messageDiv.setAttribute('data-time', message.time);
    messageDiv.setAttribute('data-user', message.from);
    messageContainer.prepend(messageDiv);

    if (!document.querySelector(`style.fromUser-style-${message.from}`)) {
                                let style = document.createElement('style');
                                style.innerHTML = `.fromUser-${message.from} .fromText:before { 
                                    content: '';
                                    display: block;
                                    width: 3.863vh;
                                    height: 3.863vh;
                                    position: absolute;
                                    top: 0;
                                    left: -0.86vw;
                                    background: url('${window.userDetails[message.from]?.profileImage || 'default-profile.png'}');
                                    background-size: cover;
                                }
                                .fromUser-${message.from} {
                                    margin-top: 2.5vh;
                                }`;
                                document.head.appendChild(style); // Append the style element to the head for CSS
                            }
    }              
  },
  onAudioMessage: (message) => {
    window.getHistoryMessages();
  },
  onTokenWillExpire: () => {
    //console.log("Token will expire soon.");
    // Optionally, refresh token here]
    window.accessChat();
  },
  onTokenExpired: () => {
    //console.log("Token has expired.");
    window.accessChat();

    // Handle re-login or token refresh here
  },
  onError: (error) => {
    console.log("Error: " + error.message);
  }
});

// Call this function appropriately in your app's flow

/*
    // Initialize both RTC and RTM clients
    initializeRTC();
    initializeRTM();

      //joinChannel();
*/  
    